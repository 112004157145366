<template>
  <div class="GroupInvitationView">
    <div @click="back()">
      back to overview
    </div>
    <br>
    <GroupInvitationPanel
      v-if="groupInvitation"
      :group-invitation="groupInvitation" />
    <div v-else>
      (Did not find this group invitation)
    </div>
  </div>
</template>

<script>
import GroupInvitationPanel from '../components/GroupInvitationPanel';

export default {
    components: { GroupInvitationPanel },
    props: {
        groupId: {
            type: String,
            required: true
        },
        groupInvitationId: {
            type: String,
            required: true
        }
    },
    computed: {
        group() {
            return this.$store.getters['moduleSocial/getGroup'](this.groupId);
        },
        groupInvitation() {
            if (!this.group) {
                return null;
            }
            return this.group.groupInvitationsByGroupIdList.find(gi => gi.id === this.groupInvitationId);
        }
    },
    beforeCreate() {
        this.$store.dispatch('moduleSocial/refresh');
    },
    methods: {
        back() {
            this.$router.push('/social/overview');
        }
    }
};
</script>

<style scoped lang="scss">
.GroupInvitationView {
    background-color: white;
    height: 100%;
    overflow-y: auto;
    padding: 1em;
}
</style>
