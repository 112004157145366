<template>
  <div class="GroupInvitationPanel">
    <div><b>GroupInvitation</b> {{ groupInvitation.id }}</div>
    <br>
    <div>email: {{ groupInvitation.email }}</div>
    <br>
    <div v-if="alreadyIsMember">
      already a member of group!
    </div>
    <br>
    <div>
      <template v-if="alreadyIsMember">
        <Button
          label="Goto group"
          @click="gotoGroup()" />
                &nbsp;
      </template>
      <template v-else>
        <Button
          label="Decline"
          @click="decline()" />
                &nbsp;
        <Button
          label="Accept"
          @click="accept()" />
      </template>
    </div>
  </div>
</template>

<script>
import Button from './Button';

export default {
    components: { Button },
    props: {
        groupInvitation: {
            type: Object,
            required: true
        }
    },
    computed: {
        group() {
            return this.$store.getters['moduleSocial/getGroup'](this.groupInvitation.groupId);
        },
        alreadyIsMember() {
            const userId = this.$store.state.moduleSocial.userId;
            return !!(this.group && this.group.membersByGroupIdList.some(m => m.userId === userId));
        }
    },
    methods: {
        async decline() {
            await this.$store.dispatch('moduleSocial/deleteGroupInvitation', {
                groupInvitationId: this.groupInvitation.id
            });
            this.$router.push('/social/overview');
        },
        async accept() {
            await this.$store.dispatch('moduleSocial/deleteGroupInvitation', {
                groupInvitationId: this.groupInvitation.id
            });
            await this.$store.dispatch('moduleSocial/createMember', { groupId: this.groupInvitation.groupId });
            this.$router.push(`/social/group/${this.groupInvitation.groupId}`);
        },
        gotoGroup() {
            this.$router.push(`/social/group/${this.groupInvitation.groupId}`);
        }
    }
};
</script>

<style scoped lang="scss">
.GroupInvitationPanel {
}
</style>
